import React, { useState, useEffect } from "react";
import SubTitle from "../../components/SubTitle/SubTitle";
import Card from "../../components/Card/Card.jsx";
import PersonalInfo from "./PersonalInfo";
import ProfessionalInfo from "./ProfessionalInfo";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./_addEmpl.scss";
import Button from "../../components/Button/index";
import { createUser } from "../../slices/user";
import Upload from "../../components/UploadImg/index";
import { useDispatch } from "react-redux";
import Notice from "../../components/Notice";

const Index = (props) => {
  const dispatch = useDispatch();
  const { handleClose, id } = props;
  const [myImg, setMyImg] = useState(false);
  const [status, setStatus] = useState(false);
  const [departement, setDepartment] = useState(false);
  const [role, setRole] = useState(false);
  const [gender, setGender] = useState(false);
  const [resetImg, setResetImg] = useState(false);
  const [resetBalance, setResetBalance] = useState(false);

  const resetHandler = () => {
    setStatus(true);
    setRole(true);
    setGender(true);
    setDepartment(true);
    setResetImg(true);
    setMyImg(false);
  };

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    birthday: null,
    gender: "",
    address: "",
    hiringDate: null,
    jobTitle: "",
    department: "",
    status: "",
    role: "",
    balance: "",
    authorisationBalance: "",
    img: "",
    leavesInStock: "",
    submit: null,
  };

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is Required!"),
    fullName: Yup.string()

      .matches(
        /^([A-Za-z-ا-ي\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain alphabetic letters."
      )
      .required("Name is Required!"),

    phone: Yup.string()
      .matches(
        "(74|71|78|70|72|9|4|2|5|73|75|76|77|79)[0-9]{6}",
        "Phone number formatted incorrectly"
      )
      .matches(/^\S*$/, "Phone number should not have spaces")
      .max(8)
      .required("Phone number is required!"),

    gender: Yup.string().required("Gender is Required!"),
    birthday: Yup.object().nullable().required("Birthday is Required!"),
    address: Yup.string().required("Address is Required!"),
    hiringDate: Yup.object().nullable().required("Hiring Date is Required!"),
    jobTitle: Yup.string()
      .required("Job Title is Required!")
      .matches( /^([A-Za-z-ا-ي\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'/]+)$/gi,
         "Name can only contain alphabetic letters and some special caracters like ' /"
      ),
    department: Yup.string().required("Department is Required!"),
    status: Yup.string().required("Status is Required!"),
    role: Yup.string().required("Role is Required!"),
    balance: Yup.string().required("Balance is Required!"),

    authorisationBalance: Yup.string().required(
      "Authorisation Balance is Required!"
    ),
  });

  const onSubmit = async (values, submitProps) => {
    const data = {
      fullName: values?.fullName,
      img: values.img ? values?.img : undefined,
      email: values.email,
      phone: values.phone,
      birthday: new Date(
        values.birthday?.month +
          "/" +
          values.birthday?.day +
          "/" +
          values.birthday?.year
      ),
      gender: values.gender,
      address: values.address,
      hiringDate: new Date(
        values.hiringDate?.month +
          "/" +
          values.hiringDate?.day +
          "/" +
          values.hiringDate?.year
      ),
      jobTitle: values.jobTitle,
      department: values.department,
      role: values.role,
      status: values.status,
      balance: values.balance,
      leavesInStock: values.leavesInStock ? values.leavesInStock : undefined,
      authorisationBalance: values.authorisationBalance,
    };

    await dispatch(createUser(data)).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message,
          open: true,
          state: "error",
        });
        submitProps.setStatus({ success: false });
        submitProps.setSubmitting(false);
      } else {
        if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });
          submitProps.setStatus({ success: true });
          submitProps.setSubmitting(true);
          setTimeout(() => {
            handleClose(id);
          }, 1500);
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={resetHandler}
    >
      {(formik) => {
        return (
          <>
            <Notice
              open={notice.open}
              text={notice.text}
              state={notice.state}
            />
            <Form className="add-empl-wrraper">
              <div className="header-upload-user-info">
                <Upload
                  isUpoding={true}
                  formik={formik}
                  setMyImg={setMyImg}
                  myImg={myImg}
                  setResetValue={setResetImg}
                  resetValue={resetImg}
                  isUpdate={true}
                  addUser={true}
                  width={"70px"}
                  height={"70px"}
                />
              </div>

              <div className="form-total-wrraper">
                <Card className="cardOne-wrapper">
                  <SubTitle
                    subTitle={"Personal Info"}
                    className="blueColor subtitle__employee_info"
                  />
                  <PersonalInfo
                    formik={formik}
                    setResetValue={setGender}
                    resetValue={gender}
                  />
                </Card>

                <Card className="card-to-wrapper">
                  <div className="employee-info-main ">
                    <SubTitle
                      subTitle={"Professional Info"}
                      className="blueColor subtitle__employee_info"
                    />
                    <ProfessionalInfo
                      myImg={myImg}
                      setResetValue={setResetImg}
                      addUser={props.addUser}
                      formik={formik}
                      setDepartment={setDepartment}
                      setStatus={setStatus}
                      setRole={setRole}
                      departement={departement}
                      status={status}
                      Role={role}
                      resetValue={resetBalance}
                      // setResetBalance={setResetBalance}
                    />
                  </div>
                </Card>
              </div>
              <div className="ta-btns-group">
                <Button
                  text="Save"
                  type="submit"
                  className={
                    !formik.isValid || formik.isSubmitting
                      ? " blueButton disabled-button "
                      : "blueButton "
                  }
                />

                <Button className="whiteButton" text="Reset" type="reset" />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default Index;
